<template>
  <div class="card">
    <Paginator
      :first="first"
      :rows="rows"
      :totalRecords="totalRecords"
      :rowsPerPageOptions="[10, 20, 30]"
      :page="currentPage"
      @page="onPageChange"
    />
  </div>
</template>

<script>
import { watch, ref } from "vue";
import Paginator from "primevue/paginator";

export default {
  components: {
    Paginator,
  },
  props: {
    totalRecords: {
      type: Number,
      required: true,
    },
    rows: {
      type: Number,
      default: 10,
    },
    page: {
      type: Number,
      default: 0,
    },
  },

  setup(props, { emit }) {
    const first = ref(props.page * props.rows);
    const currentPage = ref(props.page);

    const onPageChange = (event) => {
      first.value = event.first;
      currentPage.value = event.page;

      emit("page-change", event);
    };

    watch(
      () => props.page,
      (newPage) => {
        currentPage.value = newPage;
        first.value = newPage * props.rows;
      }
    );

    return {
      first,
      currentPage,
      onPageChange,
    };
  },
};
</script>

<style scoped></style>
